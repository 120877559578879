<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Fair Type" vid="fair_type" rules="required|min_value:1" v-slot="{ errors }">
              <b-form-group
                label-for="year">
                <template v-slot:label>
                  {{ $t('globalTrans.type') }} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="data.fair_type"
                  :options="fairTypeList"
                  id="fair_type"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Duration" vid="duration" rules="required|min_value:1" v-slot="{ errors }">
              <b-form-group
                label-for="duration">
                <template v-slot:label>
                  {{$t('ditfConfig.duration') + ' ' + $t('ditfConfig.type')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="data.duration"
                  :options="durationList"
                  id="duration"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Fee" vid="fee" :rules="{required: true, regex: /^\d*\.?\d*$/}" v-slot="{ errors }">
              <b-form-group
                label-for="dollar_rate">
                <template v-slot:label>
                  {{ $t('ditfConfig.fee') }}<span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="fee"
                  v-model="data.fee"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { fairFeeStore, fairFeeUpdate } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        duration: 0,
        fair_type: 0,
        fee: ''
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getEditData()
      this.data = tmp
    }
  },
  computed: {
    // static fair type as local or international
    fairTypeList () {
      return [
        { value: 1, text: this.$i18n.locale === 'bn' ? 'স্থানীয়' : 'Local' },
        { value: 2, text: this.$i18n.locale === 'bn' ? 'আন্তর্জাতিক' : 'International' }
      ]
    },
    durationList () {
      return [
        { value: 1, text: this.$i18n.locale === 'bn' ? '০১ - ০৫ দিন' : '01 -05 days' },
        { value: 2, text: this.$i18n.locale === 'bn' ? '০৬ - ১০ দিন' : '06 -10 days' },
        { value: 3, text: this.$i18n.locale === 'bn' ? '১১ - ১৫ দিন' : '11 - 15 days' },
        { value: 4, text: this.$i18n.locale === 'bn' ? '১৬ - ২০ দিন' : '16 - 20 days' },
        { value: 5, text: this.$i18n.locale === 'bn' ? '২১ - ২৫ দিন' : '21 -25 days' },
        { value: 6, text: this.$i18n.locale === 'bn' ? '২৬ - ৩০ দিন' : '26 - 30 days' }

      ]
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(internationalTradeFairServiceBaseUrl, `${fairFeeUpdate}/${this.id}`, this.data)
      } else {
        result = await RestApi.postData(internationalTradeFairServiceBaseUrl, fairFeeStore, this.data)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getEditData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
